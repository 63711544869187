import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { rem, rgba } from 'polished';

import { colors } from '../helpers/variables';
import { gapable } from '../helpers/traits';
import Icon from './Icon';

/** Wrapper */
const Wrapper = styled.button`
    box-shadow: 0 0 ${rem(3)} ${rem(3)} ${rgba(colors.black, 0.15)};
    align-items: center;
    background-color: ${colors.white};
    border-radius: 50%;
    border: none;
    color: ${colors.primary};
    cursor: pointer;
    display: flex;
    height: ${rem(40)};
    justify-content: center;
    margin: 0;
    padding: 0;
    width: ${rem(40)};

    ${gapable()};

    ${({ size }) =>
        size === 'large' &&
        css`
            font-size: 1.5em;
            height: ${rem(50)};
            width: ${rem(50)};
        `};
`;

/**
 * Erzeugt eine runde Schaltfläche mit Icon; wird zum toggeln von Contents etc. verwenden
 * @param {string} props.size Optional: Gibt die Größe des Togglers an (mögliche Werte standard / large)
 * @param {string} props.icon Optional: Typ bzw. Name des Icons (Standard: Bars)
 * @param {function} props.onClick Funktion, die bei Klick auf den Toggler ausgeführt wird
 * @example
 *      <Toggle size="large" icon="chicken" onClick={this.onClick} />
 */
const Toggle = ({ className, size, icon, onClick, ...props }) => (
    <Wrapper className={className} size={size} onClick={onClick} {...props}>
        <Icon type={icon} />
    </Wrapper>
);

Toggle.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
    className: null,
    size: 'standard',
    icon: 'bars',
};

export default Toggle;
