import React from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import { rem } from 'polished';

import { scrollTo } from '../helpers/utils';
import { colors } from '../helpers/variables';
import { mq } from '../helpers/stylehelpers';

import Toggle from './Toggle';

/**
 * Link
 * @type {StyledComponent}
 */
const Link = styled(Toggle)`
    background-color: ${colors.primaryColor};
    bottom: 15vh;

    position: fixed;
    right: ${rem(15)};
    transform: translateY(-50%);
    transition: opacity 0.25s, background-color 0.25s;
    z-index: 1001;

    > svg {
        color: ${colors.primary};
    }

    ${mq.large`
        right: ${rem(20)};
    `};

    ${mq.xxlarge`
        right: ${rem(45)};
    `};

    ${mq.xxxlarge`
        right: ${rem(60)};
    `};

    &:hover {
        > svg {
            color: ${colors.white};
        }
        background-color: ${colors.primary};
    }

    ${({ show }) =>
        show ? `opacity: 1; pointer-events:auto;` : `opacity: 0; pointer-events: none;`};
`;

/**
 * ScrollTop Komponentne
 */
class ScrollTop extends React.Component {
    /**
     * State
     * @type {Object}
     */
    state = {
        show: false,
    };

    /**
     * Gibt an, wie weit gescrollt werden muss, bevor Element angezeigt wird
     * @type {Number}
     */
    threshold = 700;

    /**
     * On Mount
     */
    componentDidMount() {
        window.addEventListener('scroll', this.throttledScroll);
    }

    /**
     * On Unmount
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.throttledScroll);
    }

    /**
     * Überwacht das Scrollen
     * @param {Event} evt Scroll-Event
     */
    handleScroll = () => {
        let isOverThreshold = false;
        if (window.scrollY > this.threshold) {
            isOverThreshold = true;
        }
        this.setState({
            show: isOverThreshold,
        });
    };

    /**
     * Throttled HandleScroll
     * @type {Function}
     */
    /* eslint-disable-next-line react/sort-comp */
    throttledScroll = throttle(this.handleScroll, 300);

    /**
     * Render
     */
    render() {
        const { show } = this.state;
        return (
            <Link
                href="#top"
                icon="longArrowUp"
                onClick={scrollTo}
                show={show}
                aria-label="nach oben"
            />
        );
    }
}

export default ScrollTop;
