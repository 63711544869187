import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';
import ScrollTop from './ScrollTop';
import GlobalStyles from './GlobalStyles';
import CookieControl from './CookieControl';
import logo from '../images/logo.svg';

/**
 * Rendert ein Standard-Layout
 * @param {Node} props.children Der Inhalt
 * @param {string} props.title Der Seitentitel
 * @param {string} props.description Die Meta-Description
 */
const Layout = ({ children, title, description }) => (
    <StaticQuery
        query={graphql`
            {
                allPagestructureJson {
                    edges {
                        node {
                            url
                            title
                            subpages {
                                title
                                anchor
                                url
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            const pageStructure = data.allPagestructureJson.edges.map(({ node }) => node);
            return (
                <>
                    <Helmet
                        title={`Schütz Dienstleistungen - ${title}`}
                        meta={[
                            { name: 'description', content: description },
                            {
                                name: 'keywords',
                                content:
                                    'Reinigung,Teppichbodenreinigung,Fensterreinigung, Wintergartenreinigung, Glaskonstruktionsreinigung, Schädlingsbekämpfung, Kammerjäger, Gebäudereinigung, Wespen,Kakerlaken, Schaben, Wespennotdienst, Ratten, Mäuse, Flöhe, Fliegen, Maden,Ameisen, Motten, Messigkäfer, Kugelkäfer, Kornkäfer, Holzwurm, Holzbock, Milben,Maden, Bettwanzen, Kleidermotte, Leuse, Taubenschutz, Tauben, Taubenabwehr, Marder, Wühlmäuse, Maulwurf, Holzschutz, Schimmelpilze, Bautenschutz, Pflanzenschutz, Schimmelschutz, Voratschutz, Hygieneschutz, Hygiene, Hygieneberatung, Hygieschulung, Desinfektion, Esslingen, Stuttgart, Nürtingen,Reutlingen, Tüingen,Böblingen, Filderstadt, Echterdingen, Leinfelden, Ostfildern, Waldenbuch, Dettenhausen',
                            },
                            { name: 'robots', content: 'index' },
                        ]}
                        htmlAttributes={{
                            lang: 'de',
                        }}
                    />
                    <GlobalStyles />
                    <Header
                        pageStructure={pageStructure}
                        logo={{
                            image: logo,
                            alt: 'Logo von Schütz Dienstleistungen – rund ums Haus',
                            title: 'Schütz Dienstleistungen – rund ums Haus',
                        }}
                    />
                    {children}
                    <Footer />
                    <ScrollTop />
                    <CookieControl />

                    {/* eslint-disable react/no-danger */}
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: `
                            {
                                "@context": "http://schema.org",
                                "@type": "Organization",
                                "name": "Schütz Dienstleistungen / Reinigung & Wartung ",
                                "description": "Dienstleistungen rund ums Haus von Peter Schütz",
                                "logo": "https://www.schuetzdienstleistungen/logo.png",
                                "url": "https://www.schuetzdienstleistungen.de",
                                "telephone": "07127 / 50311",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "Schönbuchstrasse 19/1",
                                    "addressLocality": "Aichtal-Neuenhaus",
                                    "postalCode": "72631",
                                    "addressCountry": "Germany"
                                }
                            }
                        `,
                        }}
                    />
                    {/* eslint-enable react/no-danger */}
                </>
            );
        }}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
};

Layout.defaultProps = {
    description: '',
};

export default Layout;
