import React from 'react';
import PropTypes from 'prop-types';
import { rem, rgba } from 'polished';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { mq, globalFramePadding } from '../helpers/stylehelpers';
import { colors } from '../helpers/variables';

import MetaNavigation from './MetaNavigation';

import Toggle from './Toggle';
import Navigation from './Navigation';

/** Wrapper um den Header herum */
const Wrapper = styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: ${rem(15)};
    position: relative;
    transition: padding 0.5s;
    width: 100%;

    ${mq.mediumDown`
        &:before {
            background-color: ${rgba(colors.black, 0.5)};
            content: '';
            height: 100%;
            height: 100vh;
            right: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity 0.5s, transform 0.5s, width 0.5s;
            width: 0;
            z-index: 3;

            ${({ offCanvasActive }) =>
                offCanvasActive &&
                `
                opacity: 1;
                width: 100%;
                width: 100vw;

            `};
        }

    `};

    ${mq.large`
        justify-content: space-between;
        padding-top: ${rem(20)};
        padding-bottom: ${rem(20)};
    `};

    ${globalFramePadding};
`;

/** Wrapper um das Logo herum */
const LogoLink = styled(Link)`
    margin: 0 ${rem(15)} 0 0;
    max-width: ${rem(160)};
    display: flex;
    align-items: flex-start;
    font-size: 0;

    ${mq.medium`
        max-width: ${rem(180)};
    `};

    ${mq.large`
        margin: 0 ${rem(30)} 0 0;
        max-width: ${rem(200)};
    `};

    ${mq.xlarge`
        max-width: ${rem(250)};
    `};
`;

/** Logo als Bild */
const Logo = styled.img`
    text-align: center;
    width: 100%;
`;

/** Toggler */
const StyledToggle = styled(Toggle)`
    flex: 0 0 auto;
    position: relative;

    ${({ increaseZIndex }) =>
        increaseZIndex &&
        `
        z-index: 10;
    `};

    ${mq.large`
        display: none;
    `};
`;

/**
 * Wrapper für Suche & Meta
 */
const MetaWrapper = styled.div`
    ${mq.large`
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin: 0 0 ${rem(20)} 0;
    `};
    ${mq.xlarge`
        margin-bottom: ${rem(25)};
    `};
`;

/** MetaNavigation */
const StyledMetaNavigation = styled(MetaNavigation)`
    display: none;
    position: relative;
    ${mq.large`
        display: flex;
    `};
`;

/** Wrapper für die Navigation + Meta */
const NavigationWrapper = styled.div`
    justify-content: flex-end;
    flex: 0 0 auto;
    z-index: 3;
`;

/**
 * Header Komponente, die Logo, Navi und Meta darstellt
 * @param {Object} props.navStructure Die Struktur der Navigation
 */
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offCanvasActive: false,
        };
    }

    /**
     * Bei Componente Mount
     * Eventuell vorhandene Klasse auf Body entfernen, wenn nicht auf mobile
     */
    componentDidMount = () => {
        this.closeOffCanvas();
    };

    /**
     * Toggelt OffCanvas
     * @param  {MouseEvent} evt Klick-Event
     */
    toggleOffCanvas = (evt) => {
        evt.preventDefault();
        const html = document.querySelector('html');
        html.classList.toggle('off-canvas-active');
        // eslint-disable-next-line prettier/prettier
        this.setState(prevState => ({
            offCanvasActive: !prevState.offCanvasActive,
        }));
    };

    closeOffCanvas = () => {
        const html = document.querySelector('html');
        html.classList.remove('off-canvas-active');
        this.setState({
            offCanvasActive: false,
        });
    };

    /**
     * Render
     */
    render() {
        const { pageStructure, logo } = this.props;
        const { offCanvasActive } = this.state;

        return (
            <Wrapper offCanvasActive={offCanvasActive} id="top">
                <LogoLink to="/">
                    <Logo src={logo.image} alt={logo.alt} title={logo.title} />
                </LogoLink>
                <NavigationWrapper>
                    <MetaWrapper>
                        <StyledMetaNavigation
                            items={[
                                {
                                    title: '07127 / 50311',
                                    href: 'tel:07127 / 50311',
                                    icon: 'phone',
                                },
                                {
                                    title: 'info@schuetzdienstleistungen.de',
                                    href: 'mailto:info@schuetzdienstleistungen.de',
                                    icon: 'mail',
                                },
                            ]}
                        />
                    </MetaWrapper>

                    <StyledToggle
                        icon={offCanvasActive ? 'close' : 'bars'}
                        aria-label={offCanvasActive ? 'Schließe Navigation' : 'Öffne Navigation'}
                        onClick={this.toggleOffCanvas}
                        data-toggletype="offCanvasActive"
                        increaseZIndex
                    />
                    <Navigation
                        pageStructure={pageStructure}
                        offCanvasActive={offCanvasActive}
                        closeOffCanvas={this.closeOffCanvas}
                    />
                </NavigationWrapper>
            </Wrapper>
        );
    }
}

Header.propTypes = {
    pageStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
    logo: PropTypes.shape({
        image: PropTypes.string,
        alt: PropTypes.string,
        title: PropTypes.string,
    }).isRequired,
};

export default Header;
